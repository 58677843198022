import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from 'gatsby-plugin-react-i18next'
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  {
    file(relativePath: { eq: "images/headers/our-work.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const HeaderOurWork = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query);
  const imageData = data.file.childImageSharp.fluid;

  return (
    <header className="m-headerOW">
      <BackgroundImage fluid={imageData} Tag="div" className="_wr m-headerOW__wrapper">
        <div className="_w">
          <div className="_10 _xs8 _m6 _l5">
            <div className=" m-headerOW__content">
              <h1 className="m-headerOW__content--title">
                {t('ourWork.headline')}
              </h1>
              <span className="a-line -red"></span>
              <p className="m-headerOW__content--text">
                {t('ourWork.companies.text')}
              </p>
              {/* <Link
                className="a-btn -redBorder a-hover -effectThree"
                to="/case-study"
              >
                {t('buttons.seeCaseStudy')}
                <span className="a-arrow -long"></span>
              </Link> */}
            </div>
          </div>
        </div>
      </BackgroundImage>
    </header>
  );
};

export default HeaderOurWork;
