import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import Grundfos from "../../assets/images/our-work/grundfos.png";
import Lowenfels from "../../assets/images/our-work/lowenfels.png";
import SloveniaControl from "../../assets/images/our-work/slovenia-control.png";
import Savacoop from "../../assets/images/our-work/savacoop.png";
import Proserv from "../../assets/images/our-work/proserv.png";
import Block7 from "../../assets/images/our-work/block7.png";
import Qualipet from "../../assets/images/our-work/qualipet.png";
import Celestyal from "../../assets/images/our-work/celestyal.png";
import Leifheit from "../../assets/images/our-work/leifheit.png";
import Bytetobite from "../../assets/images/our-work/bytetobite.png";
import Ulaz from "../../assets/images/our-work/ulaz.png";
import Darex from "../../assets/images/our-work/darex.png";

const Companies = () => {
  const { t } = useTranslation()
  const ourWorkContent = [
    {
      image: Savacoop,
      name: "Multitenant business solution",
    },
    {
      image: Grundfos,
      name: "QCC Application",
      caseStudyUrl: "/quality-control"
    },
    {
      image: Lowenfels,
      name: "Impuls",
    },
    {
      image: SloveniaControl,
      name: "eARO 2.0",
      caseStudyUrl: "/e-aro"
    },
    {
      image: Proserv,
      name: "AEGIS",
    },
    {
      image: Block7,
      name: "Art trade platform",
    },
    {
      image: Qualipet,
      name: "UI/UX, Frontend, Backend",
    },
    {
      image: Celestyal,
      name: "Frontend",
    },
    {
      image: Leifheit,
      name: "eCommerce",
    },
    {
      image: Bytetobite,
      name: "The whole IT ecosystem",
    },
    {
      image: Ulaz,
      name: "Web platform for house entrance",
    },
    {
      image: Darex,
      name: "eCommerce",
    },
  ];

  return (
    <section className="m-companies">
      <div className="_wr">
        <div className="_w m-companies__top">
          <div className="m-companies__top">
            <h2 className="m-companies__top--title">
              {t('ourWork.companies.headline')}
            </h2>
            <span className="a-line -red"></span>
          </div>
        </div>

        <div className="_w">
          {ourWorkContent.map(({ image, name, caseStudyUrl }, i) => {
            return (
              <div key={i} className="_m6 _l4 m-companies__item">
                <div className={`m-companies__item--content ${caseStudyUrl ? '-hasUrl' : ''}`}>
                  <img
                    className="m-companies__item--logo"
                    src={image}
                    alt=""
                  />
                  <div className="m-companies__item--text">
                    <span className="m-companies__item--name">{name}</span>
                  </div>
                  {caseStudyUrl && <Link to={caseStudyUrl} className="a-btn -redBorder">{t('buttons.discoverCaseStudy')} <span className="a-arrow -long"></span></Link>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Companies;
